<template>
  <v-card
    :color="workspaceLayerColor"
    class="mx-auto my-md-10 pt-10 text-center"
    width="100%"
    max-width="35rem"
  >
    <v-avatar
      class="mt-10 mb-5"
      size="80"
      style="border-radius: 50%"
      :style="
        isContentFilledInCorrectly ? 'border: 4px solid #74EEB4' : 'border: 4px solid #D83232'
      "
    >
      <v-icon :color="isContentFilledInCorrectly ? '#74EEB4' : '#D83232'" x-large style="zoom: 1.3">
        {{ isContentFilledInCorrectly ? 'mdi-check' : 'mdi-close' }}
      </v-icon>
    </v-avatar>

    <v-card-text class="px-8 px-md-15">
      <h1 class="headline mb-2 px-md-16 secondary--text font-weight-black">
        {{
          isContentFilledInCorrectly
            ? $t('auths.bank_id.check_successful')
            : $t('auths.bank_id.check_failed')
        }}
      </h1>
    </v-card-text>

    <v-card-text class="py-0 px-5 px-md-15">
      <v-card class="authentication-summary__bordered" :color="workspaceLayerColor" elevation="0">
        <v-card-title class="px-0 pb-0 text-left">
          <v-row align="center" justify="space-between" no-gutters>
            <v-col class="secondary--text">
              {{ $t('auths.bank_id.check_via_login_to_bank_id') }}
            </v-col>
            <v-col cols="auto">
              <v-btn icon @click="loginToBankIdAgain">
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-title>

        <StatePreview :type="bankIdFill.type" :label="bankIdFill.message" />
      </v-card>
    </v-card-text>

    <v-card-actions class="mt-5 mb-15 px-10">
      <v-btn
        class="mt-5 mx-auto"
        color="primary"
        x-large
        min-width="15rem"
        :loading="isSubmitted"
        :disabled="!isContentFilledInCorrectly"
        @click="submit"
      >
        {{ $t('aml.summary.send') }}
      </v-btn>
    </v-card-actions>

    <AlertMessage v-model="error" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { goToLinkWithTarget } from '@/common/reusable/routing';
import { authBankIdSeen } from '@verification/services/logService';
import AlertMessage from '@verification/components/AlertMessage';
import StatePreview from '@verification/components/StatePreview';

export default {
  name: 'BankIdentity',
  components: {
    AlertMessage,
    StatePreview,
  },
  data() {
    return {
      error: false,
      submitted: false,
    };
  },
  computed: {
    ...mapGetters({
      bankId: 'authsBankId/bankId',
      bankIdLink: 'authsBankId/bankIdLink',
      workspaceLayerColor: 'auths/workspaceLayerColor',
    }),
    bankIdFill() {
      return {
        type: this.isContentFilledInCorrectly ? 'success' : 'error',
        message: this.isContentFilledInCorrectly
          ? this.$t('general.successfully_verified')
          : this.$t('general.unsuccessfully_verified'),
      };
    },
    isContentFilledInCorrectly() {
      return 'submitted' === this.bankId.status;
    },
    isSubmitted() {
      return this.submitted;
    },
  },
  mounted() {
    authBankIdSeen({ authToken: this.$route.params.authToken });
  },
  methods: {
    ...mapActions({
      submitProcess: 'auths/submitProcess',
    }),
    loginToBankIdAgain() {
      return goToLinkWithTarget(this.bankIdLink, '_self');
    },
    submit() {
      this.submitted = true;
      this.submitProcess(this.$route.params.authToken)
        .then(() => {
          this.$router.push({
            name: 'authentication-done',
            params: {
              authToken: this.$route.params.authToken,
            },
          });
        })
        .catch(() => {
          this.submitted = false;
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
