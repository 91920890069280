<template>
  <div class="state-preview">
    <div :class="`state-preview__item--${type}`">
      <v-avatar size="20" class="font-weight-medium">
        <v-icon v-if="type === 'success'" color="#2B6849" small> mdi-check-circle-outline </v-icon>
        <v-icon v-if="type === 'error'" color="#D00707" small> mdi-close-circle-outline </v-icon>
      </v-avatar>

      <div class="pl-1">
        <span class="font-weight-medium">
          {{ label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatePreview',
  props: {
    type: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.state-preview {
  width: fit-content;
}

.state-preview {
  padding-bottom: 10px;
  font-size: small;

  .state-preview__item--root {
    display: flex;
    align-items: center;
    padding: 3px 8px 3px 3px;
    border-radius: 6px;
  }

  &__item--error {
    @extend .state-preview__item--root;
    background-color: #fde2e3;
    color: #d21717;
  }

  &__item--success {
    @extend .state-preview__item--root;
    background-color: #e8faf4;
    color: #2b6849;
  }
}
</style>
